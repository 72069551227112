import {Component, Inject, Input} from '@angular/core';
import {ContentComponent} from '../layout/content.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'update-password-success-panel',
    styleUrls: ['update-password-success.component.less'],
    template: `
        <form class="form" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="form-body info-page centered" fxLayout="column" fxLayoutAlign="start stretch">
                <p class="line-height-double">Your password has been successfully updated.</p>
            </div>
            <div class="form-footer" fxLayout="column" fxLayoutAlign="start stretch">
                <a *ngIf="appConfig.baseUrl" class="submit-btn" [href]="appConfig.baseUrl"
                   mat-raised-button color="primary">Sign In</a>
            </div>
        </form>
    `,
})
export class UpdatePasswordSuccessComponent {

    @Input()
    private parent: ContentComponent;

    constructor(@Inject('appConfig') public appConfig: any, private translateService: TranslateService) {
    }

    public ngOnInit(): void {
        this.translateService.get('keycloak.updatePassword').subscribe((translation) => this.parent.title = translation);
    }
}
