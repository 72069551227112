import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {APP_BASE_HREF, CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SignUpComponent} from './login/sign-up.component';
import {LoginAppComponent} from './login-app.component';
import {SignInComponent} from './login/sign-in.component';
import {CoreValidatorModule, MetadataModule, SecretVisibleModule} from 'kublr-common/src';
import {ConfigurationModule} from 'kublr-common/src';
import {PasswordStrengthIndicatorModule} from 'kublr-common/src';
import {NotificationModule} from 'kublr-common/src';
import {NgxWebstorageModule, StrategyCacheService, StrategyIndex} from 'ngx-webstorage';
import {PasswordRecoveryModule} from './password-recovery/password-recovery.module';
import {EmailVerificationComponent} from './login/email-verification.component';
import {ErrorPageComponent} from './error-page.component';
import {PipeModule} from 'kublr-common/src';
import {HeaderComponent} from './layout/header.component';
import {ContentComponent} from './layout/content.component';
import {CoreDirectiveModule} from 'kublr-common/src';
import {AccountInfoComponent} from './account/account-info.component';
import {AccountPasswordComponent} from './account/account-password.component';
import {RouterModule} from '@angular/router';
import {EventTrackingModule} from 'kublr-common/src';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AccountTotpComponent} from './account/account-totp.component';
import {LoginTotpComponent} from './login/login-totp.component';
import {TermsComponent} from './login/terms.component';
import {ProfileUpdateComponent} from './login/profile-update.component';
import {UserRegService} from './services/user-reg.service';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {SettingsProvider} from 'kublr-common/src';
import {LoginConfigTotpComponent} from './login/login-config-totp.component';
import {MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {MissingTranslationService} from 'kublr-common/src';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MatMenuModule} from '@angular/material/menu';

export function settingsFactory(settings: SettingsProvider): any {
    return () => settings.load((window as any).kublrConfig);
}

export function runtimeTranslationFactory(translate: TranslateService, locale: string) {
    return () => {
        translate.setDefaultLang('en');
        return translate.use(locale && locale !== 'en-US' ? locale : 'en').toPromise();
    };
}

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
    return new TranslateHttpLoader(http, (window as any).kublrConfig.localePath, '.json');
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CoreValidatorModule,
        ConfigurationModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatDialogModule,
        MatCardModule,
        MatDividerModule,
        MatMenuModule,
        FlexLayoutModule,
        NotificationModule,
        PasswordStrengthIndicatorModule,
        PipeModule,
        NgxWebstorageModule.forRoot({prefix: 'kublr'}),
        PasswordRecoveryModule,
        CoreDirectiveModule,
        SecretVisibleModule,
        MetadataModule.forRoot(),
        EventTrackingModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationService },
            useDefaultLang: false,
        }),
        RouterModule.forRoot([], { initialNavigation: 'disabled', relativeLinkResolution: 'legacy' }),
    ],
    declarations: [
        HeaderComponent,
        ContentComponent,
        LoginAppComponent,
        SignInComponent,
        SignUpComponent,
        TermsComponent,
        LoginTotpComponent,
        LoginConfigTotpComponent,
        EmailVerificationComponent,
        AccountInfoComponent,
        AccountPasswordComponent,
        AccountTotpComponent,
        ErrorPageComponent,
        ProfileUpdateComponent,
    ],
    providers: [
        {provide: APP_BASE_HREF, useValue: '/'},
        UserRegService,
        SettingsProvider,
        StrategyIndex,
        StrategyCacheService,
        {
            provide: APP_INITIALIZER,
            useFactory: settingsFactory,
            deps: [SettingsProvider],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: runtimeTranslationFactory,
            deps: [TranslateService, LOCALE_ID],
            multi: true,
        },
    ],
    bootstrap: [LoginAppComponent],
})
export class AppModule {
}
