import {Component, Inject, Input, OnInit} from '@angular/core';
import {User} from 'kublr-common/src';
import {AutoFocusComponent} from 'kublr-common/src';
import {EventTrackingService} from 'kublr-common/src';
import {TrackingEvent} from 'kublr-common/src';
import {ContentComponent} from '../layout/content.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'password-reset-panel[parent]',
    styleUrls: ['password-reset.component.less'],
    template: `
        <form [action]="appConfig.resetPasswordUrl" class="form"
              (submit)="form.valid ? submit($event) : false" [ngClass]="{'ng-submitted': form.submitted}" #form="ngForm"
              method="post" novalidate fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="form-body" fxLayout="column" fxLayoutAlign="start stretch">
                <div class="form-field" [controlError]="form.controls.username" [showText]="false">
                    <div class="form-label">Username or Email</div>
                    <input class="form-input" type="text" [(ngModel)]="user.username" name="username"
                           #autoFocus notBlank required tabindex="1">
                </div>
                <div class="info-container collapsible" fxLayout="row" fxLayoutAlign="start center">
                    <p class="error-text" [generalError]="form" [errorMessage]="errorMessage"></p>
                </div>
            </div>
            <div class="form-footer" fxLayout="column" fxLayoutAlign="start stretch">
                <div class="button-row">
                    <button class="no-margin" type="submit" mat-raised-button color="primary" tabindex="2">Submit</button>
                </div>
                <div class="divider-container">
                    <mat-divider></mat-divider>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <a *ngIf="appConfig.baseUrl" class="sign-action upper sign-action-top" [href]="appConfig.baseUrl" tabindex="3">
                        <img class="back-icon" [src]="appConfig.imagesPath + 'icons/icon_arrow_chevron_right.svg'"
                             alt="icon_arrow_chevron_right.svg"/>
                        <span class="back-icon">&#160;&#160;&#160;Back to sign in</span>
                    </a>
                </div>
            </div>
        </form>
    `,
})
export class PasswordResetComponent extends AutoFocusComponent implements OnInit {

    public user: User = new User();

    @Input()
    public parent: ContentComponent;

    public errorMessage: string;

    constructor(@Inject('appConfig') public appConfig: any, private tracker: EventTrackingService,
                private translateService: TranslateService) {
        super();
    }

    public ngOnInit(): void {
        this.translateService.get('keycloak.restorePassword').subscribe((translation) => this.parent.title = translation);
        this.parent.withLinkButton = !!this.appConfig.baseUrl;

        if (this.appConfig.alert) {
            const self = this;
            setTimeout(() => {
                self.errorMessage = self.appConfig.alert.text;
            });
        }
    }

    public submit(event: Event): void {
        this.tracker.trackEvent(new TrackingEvent('Password Restore', 'Profile'));

        (event.srcElement as any).submit();
    }
}
