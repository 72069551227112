import {Component, Inject, Input, OnInit} from '@angular/core';
import {NotificationService} from 'kublr-common/src';
import {AlertComponent} from '../common/alert.component';
import {ContentComponent} from '../layout/content.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'totp-panel[parent]',
    styleUrls: ['login-totp.component.less'],
    template: `
        <form id="signInForm" [action]="appConfig.logInUrl" class="form"
              (submit)="form.valid ? $event.srcElement.submit() : false"
              [ngClass]="{'ng-submitted': form.submitted}" #form="ngForm" method="POST" novalidate
              fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="form-body" fxLayout="column" fxLayoutAlign="start stretch">
                <div class="form-field" [controlError]="form.controls.username" [showText]="false">
                    <div class="form-label" i18n="@@loginTotpOneTimeCode">One-time code</div>
                    <input class="form-input" type="text" matInput id="otp" name="otp"
                        [(ngModel)]="otp" autocomplete="off"
                        #autofocus notBlank required tabindex="1"/>
                </div>
                <div class="info-container" fxLayout="row" fxLayoutAlign="start center">
                    <p class="error-text" [generalError]="form" [errorMessage]="errorMessage"></p>
                </div>
            </div>
            <div class="form-footer" fxLayout="column" fxLayoutAlign="start stretch">
                <div class="button-row">
                    <button class="no-margin" type="submit" mat-raised-button color="primary" name="login" i18n="@@logInBtn">
                        Log In
                    </button>
                </div>
            </div>
        </form>
    `,
})
export class LoginTotpComponent extends AlertComponent implements OnInit {

    public otp: string;

    @Input()
    public parent: ContentComponent;

    public errorMessage: string;

    constructor(@Inject('appConfig') public appConfig: any, protected ns: NotificationService,
                private translateService: TranslateService) {
        super(appConfig, ns);
    }

    public ngOnInit(): void {
        this.translateService.get('keycloak.signIn').subscribe((translation) => this.parent.title = translation);

        if (this.appConfig.alert) {
            const self = this;
            setTimeout(() => {
                self.errorMessage = self.appConfig.alert.text;
            });
        }
    }
}
