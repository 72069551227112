import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {EventTrackingService, TrackingEvent} from 'kublr-common/src';

@Component({
    selector: '.content-wrap',
    styleUrls: ['login-app.component.less'],
    template: `
        <div [ngClass]="{'cp-mode': appConfig.cpMode}">
            <header-component></header-component>
            <div id="loginAppContent" [ngSwitch]="appConfig.state">
                <content-component #contentComponent>
                    <sign-in-panel *ngSwitchCase="'login'"
                                   [parent]="contentComponent"></sign-in-panel>
                    <totp-panel *ngSwitchCase="'login-otp'"
                                [parent]="contentComponent"></totp-panel>
                    <login-config-totp-panel *ngSwitchCase="'login-config-totp'"
                                            [parent]="contentComponent"></login-config-totp-panel>
                    <sign-up-panel *ngSwitchCase="'sign-up'"
                                   [parent]="contentComponent"></sign-up-panel>
                    <terms-panel *ngSwitchCase="'terms'"
                                 [parent]="contentComponent"></terms-panel>
                    <password-reset-panel *ngSwitchCase="'reset-password'"
                                          [parent]="contentComponent"></password-reset-panel>
                    <reset-password-success-panel *ngSwitchCase="'reset-password-success'"
                                                  [parent]="contentComponent"></reset-password-success-panel>
                    <password-update-panel *ngSwitchCase="'update-password'"
                                           [parent]="contentComponent"></password-update-panel>
                    <profile-update-panel *ngSwitchCase="'complete-profile'"
                                          [parent]="contentComponent"></profile-update-panel>
                    <email-verification *ngSwitchCase="'verify-email'"
                                        [parent]="contentComponent"></email-verification>
                    <update-password-success-panel *ngSwitchCase="'update-password-success'"
                                                   [parent]="contentComponent"></update-password-success-panel>
                    <account-info-panel *ngSwitchCase="'account-info'"
                                        [parent]="contentComponent"></account-info-panel>
                    <account-password-panel *ngSwitchCase="'account-password'"
                                            [parent]="contentComponent"></account-password-panel>
                    <account-totp-panel *ngSwitchCase="'account-totp'"
                                        [parent]="contentComponent"></account-totp-panel>
                    <error-page *ngSwitchCase="'error'"
                                [parent]="contentComponent"></error-page>
                </content-component>
            </div>
        </div>
    `,
})
export class LoginAppComponent implements OnInit {

    constructor(@Inject('appConfig') public appConfig: any, private tracker: EventTrackingService) {

        this.tracker.bootManual().subscribe(() => {
            tracker.autoViewTracking(false);
            tracker.trackView(new TrackingEvent(null, this.appConfig.state));
        });
    }

    public ngOnInit(): void {
    }

    // Event listener that required to resolve cycle dependencies between http response interceptors and event tracking
    @HostListener('document:weak-linked-event', ['$event'])
    public onWeakLinkedEvent(event: CustomEvent<TrackingEvent>) {
        this.tracker.trackEvent(event.detail);
    }
}
