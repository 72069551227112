import {Component, Inject, Input, OnInit} from '@angular/core';
import {TrackingEvent} from 'kublr-common/src';
import {EventTrackingService} from 'kublr-common/src';
import {ContentComponent} from '../layout/content.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'terms-panel[parent]',
    styleUrls: ['terms.component.less'],
    template: `
        <form id="termsForm" [action]="appConfig.logInUrl" class="form" (submit)="accepted ? submit($event) : false"
              [ngClass]="{'ng-submitted': form.submitted}" #form="ngForm" method="POST" novalidate
              fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="form-body" fxLayout="column" fxLayoutAlign="start stretch">
                <div class="form-field specific-margin-top" fxLayout="column" fxLayoutAlign="center center"
                     [controlError]="form.controls.username" [showText]="false">
                    <div>
                    <mat-checkbox  name="accepted" [(ngModel)]="accepted" tabindex="1">
                    </mat-checkbox>
                    <span i18n="@@termsAgreement">I agree with Kublr Demo
                        <a target="_blank" [href]="policyUrl + '/terms-of-use.html'">Terms of Use</a> and
                        <a [href]="policyUrl + '/privacy-policy.html'" target="_blank">Privacy Policy</a>.
                    </span>
                    </div>
                </div>
                <div class="info-container" fxLayout="row" fxLayoutAlign="start center">
                    <p class="error-text" [generalError]="form" [errorMessage]="errorMessage"></p>
                </div>
            </div>

            <div class="form-footer" fxLayout="column" fxLayoutAlign="start stretch">
                <div class="button-row">
                    <button class="no-margin" name="accept" type="submit" mat-raised-button
                            color="primary" tabindex="2" i18n="@@continueBtn">Continue</button>
                </div>
            </div>
        </form>
    `,
})
export class TermsComponent implements OnInit {

    public policyUrl = 'http://kublr-in-a-box-policy.s3-website-us-east-1.amazonaws.com';
    public accepted: boolean;

    @Input()
    public parent: ContentComponent;

    public errorMessage: string;

    constructor(@Inject('appConfig') public appConfig: any, private tracker: EventTrackingService,
                private translateService: TranslateService) {
    }

    public ngOnInit(): void {
        this.translateService.get('keycloak.terms').subscribe((translation) => this.parent.title = translation);

        if (this.appConfig.alert) {
            const self = this;
            setTimeout(() => {
                self.errorMessage = self.appConfig.alert.text;
            });
        }
    }

    public submit(event: Event): void {
        this.tracker.trackEvent(new TrackingEvent('Accept', 'Profile:Terms-of-Use'));

        (event.srcElement as any).submit();
    }
}
