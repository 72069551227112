import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {User} from 'kublr-common/src';
import {NotificationService} from 'kublr-common/src';
import {EventTrackingService} from 'kublr-common/src';
import {TrackingEvent} from 'kublr-common/src';
import {UserRegService} from '../services/user-reg.service';
import {verticalSlide} from 'kublr-common/src';
import {MessageSeverity} from 'kublr-common/src';
import {ContentComponent} from '../layout/content.component';
import {AutoFocusComponent} from 'kublr-common/src';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'profile-update-panel',
    animations: [verticalSlide],
    styleUrls: ['profile-update.component.less'],
    template: `
        <form [action]="appConfig.completeProfileUrl" class="form account-from"
              (submit)="f.valid && (appConfig.cpMode ? accepted : true) ? submit($event) : false"
              [ngClass]="{'ng-submitted': f.submitted}"
              #f="ngForm" method="POST" novalidate fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="form-body" fxLayout="column" fxLayoutAlign="start stretch">
                <input type="hidden" name="username" value="{{user.username}}">
                <input type="hidden" name="user.attributes.kublr_license" value="{{user.attributes.kublr_license}}">
                <input *ngIf="appConfig.cpMode" type="hidden" name="user.attributes.trackingEnabled"
                       value="{{user.attributes.trackingEnabled.toString()}}">
                <div class="form-field" [controlError]="f.controls.firstName" [showText]="false">
                    <div class="form-label" i18n="@@profileUpdateFirstName">First Name</div>
                    <input class="form-input" type="text" [(ngModel)]="user.firstName" placeholder="First Name"
                           i18n-placeholder="@@profileUpdateFirstNamePlaceholder"
                           maxlength="16" name="firstName" notBlank required>
                </div>
                <div class="form-field" [controlError]="f.controls.lastName" [showText]="false">
                    <div class="form-label" i18n="@@profileUpdateLastName">Last Name</div>
                    <input class="form-input" type="text" name="lastName" [(ngModel)]="user.lastName" placeholder="Last Name"
                           i18n-placeholder="@@profileUpdateLastNamePlaceholder"
                           maxlength="24" notBlank required>
                </div>
                <div class="form-field" [controlError]="f.controls.email" [showText]="false">
                    <div class="form-label" i18n="@@profileUpdateEmail">Email</div>
                    <input class="form-input" type="email" name="email" [(ngModel)]="user.email" placeholder="Email"
                           i18n-placeholder="@@profileUpdateEmailPlaceholder"
                           maxlength="64" email notBlank required>
                </div>
                <div *ngIf="appConfig.cpMode" class="divider-container" fxLayout="column"
                     fxLayoutAlign="space-between" fxLayoutGap="1.8em">
                    <mat-checkbox id="allow-tracking" class="statistic-field"
                                  [(ngModel)]="user.attributes.trackingEnabled" [ngModelOptions]="{standalone: true}"
                                  i18n="@@profileUpdateCollectStatistics">
                        Allow Kublr to collect statistics and usage data
                    </mat-checkbox>
                    <mat-divider fxFlex="100"></mat-divider>
                </div>
                <mat-checkbox *ngIf="appConfig.cpMode" class="terms-field"  name="accepted" [(ngModel)]="accepted"
                              i18n="@@profileUpdateAgreement">
                    I agree with Kublr Demo
                    <a target="_blank" [href]="policyUrl + '/terms-of-use/'">Terms of Use</a> and
                    <a [href]="policyUrl + '/privacy-policy/'" target="_blank">Privacy Policy</a>.
                </mat-checkbox>
                <div class="info-container collapsible" fxLayout="row" fxLayoutAlign="space-between center">
                    <p class="error-text" [generalError]="f" [errorMessage]="errorMessage"></p>
                </div>
            </div>
            <div class="form-footer" fxLayout="column" fxLayoutAlign="start stretch">
                <div class="button-row">
                    <button [disabled]="appConfig.cpMode ? !accepted : false" type="submit"
                            mat-raised-button color="primary" class="no-margin" i18n="@@saveBtn">
                        Save
                    </button>
                </div>
            </div>
        </form>
    `,
})
export class ProfileUpdateComponent extends AutoFocusComponent implements OnInit, OnDestroy {
    public user: User = new User();
    public policyUrl = 'https://docs.kublr.com';
    public accepted: boolean;

    @Input()
    public parent: ContentComponent;
    public errorMessage: string;

    protected subscriptions: Subscription = new Subscription();

    constructor(@Inject('appConfig') public appConfig: any, protected ns: NotificationService, private tracker: EventTrackingService,
                public userRegService: UserRegService, private translateService: TranslateService) {
        super();

        // prevent to show Update Profile welcome message
        this.appConfig.alert = null;

        if (this.appConfig.user) {
            this.user = Object.assign(this.user, this.appConfig.user);
        }

        this.user.attributes.trackingEnabled = true;
    }

    public ngOnInit(): void {
        this.translateService.get(`keycloak.${this.appConfig.cpMode ? 'completeYourProfile' : 'updateYourProfile'}`)
            .subscribe((translation) => this.parent.title = translation);

        if (this.appConfig.alert) {
            const self = this;
            setTimeout(() => {
                self.errorMessage = self.appConfig.alert.text;
            });
        }

        if (this.appConfig.cpMode) {
            this.tracker.trackEventGA(new TrackingEvent('Complete Profile - Visit', 'Account - Action'));
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public submit(event: Event): void {
        if (this.appConfig.cpMode) {
            this.tracker.trackEventGA(new TrackingEvent('Complete Profile - Submit Click', 'Account - Action'));

            if (!!this.user.attributes.kublr_license) {
                this.complete(event);
                return;
            }

            this.subscriptions.add(this.userRegService.registerUser(this.user).subscribe(
            (license: any) => {
                    this.user.attributes.kublr_license = license.id || this.appConfig.kublrLicense;

                    setTimeout(() => {
                        this.complete(event);
                    });
                },
                (err) => {
                    if (this.appConfig.cpMode) {
                        this.tracker.trackEventGA(new TrackingEvent('Complete Profile - Error', 'Account - Action', err.error.Message));
                    }

                    if (err.status === 0 || err.status === 404) {
                        setTimeout(() => {
                            this.complete(event);
                        });
                    } else {
                        this.ns.notify(MessageSeverity.ERROR, err.error.Message, null, {panelClass: 'unsetHeight'});
                    }
                }));
        } else {
            this.complete(event);
        }
    }

    private trackLogin(): void {
        this.tracker.trackEventGA(new TrackingEvent('Login', 'Account - Action', `${this.appConfig.cpMode ? 'Demo' : 'Full'}`));
        this.tracker.trackEventGA(new TrackingEvent('Complete Profile - Done', 'Account - Action'));
    }

    private complete(event: Event): void {
        this.tracker.bootManual(this.user).subscribe(() => {
            this.trackLogin();
            (event.srcElement as any).submit();
        }, ((e) => console.error('start tracker error', e)));
    }
}
