import {NgModule} from '@angular/core';
import {PasswordResetComponent} from './password-reset.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {PasswordUpdateComponent} from './password-update.component';
import {CoreDirectiveModule, CoreValidatorModule, SecretVisibleModule} from 'kublr-common/src';
import {PasswordStrengthIndicatorModule} from 'kublr-common/src';
import {UpdatePasswordSuccessComponent} from './update-password-success.component';
import {ResetPasswordSuccessComponent} from './reset-password-success.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatCardModule,
        MatDividerModule,
        FlexLayoutModule,
        CoreValidatorModule,
        PasswordStrengthIndicatorModule,
        CoreDirectiveModule,
        SecretVisibleModule,
    ],
    declarations: [
        PasswordResetComponent,
        PasswordUpdateComponent,
        ResetPasswordSuccessComponent,
        UpdatePasswordSuccessComponent,
    ],
    exports: [
        PasswordResetComponent,
        PasswordUpdateComponent,
        ResetPasswordSuccessComponent,
        UpdatePasswordSuccessComponent,
    ],
})
export class PasswordRecoveryModule {

}
