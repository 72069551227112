import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormGroup, FormBuilder} from '@angular/forms';
import {PasswordValidator} from 'kublr-common/src';
import {AutoFocusComponent} from 'kublr-common/src';
import {EventTrackingService} from 'kublr-common/src';
import {TrackingEvent} from 'kublr-common/src';
import {ContentComponent} from '../layout/content.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'password-update-panel[parent]',
    styleUrls: ['password-update.component.less'],
    template: `
        <form class="form" [action]="appConfig.updatePasswordUrl"
              (submit)="form.valid ? submit($event) : false" [ngClass]="{'ng-submitted': f.submitted}"
              [formGroup]="form"
              #f="ngForm" method="post" novalidate fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="form-body" fxLayout="column" fxLayoutAlign="start stretch">
                <div class="form-field" [controlError]="form.controls.newPassword" [showText]="false">
                    <div class="form-label">New Password</div>
                    <input class="form-input" type="password" name="password-new" formControlName="newPassword"
                           [tooltip]="passwordStrengthTooltip" #autoFocus
                           strengthPassword minlength="8" required tabindex="1"
                           *secretVisible="true; imagesPath: appConfig.imagesPath">
                    <tooltip-content #passwordStrengthTooltip>
                        <password-strength-indicator [passwordControl]="form.controls.newPassword"></password-strength-indicator>
                    </tooltip-content>
                </div>
                <div class="form-field" [ngClass]="{invalid: form.hasError('passwordConfirmed')}"
                     [controlError]="form.controls.confirmPassword" [showText]="false">
                    <div class="form-label">Confirm Password</div>
                    <input class="form-input"  [ngClass]="{'ng-invalid': form.hasError('passwordConfirmed')}"
                           type="password" name="password-confirm" formControlName="confirmPassword"
                           tabindex="2" *secretVisible="true; imagesPath: appConfig.imagesPath">
                </div>
                <div class="info-container collapsible" fxLayout="row" fxLayoutAlign="space-between center">
                    <p class="error-text" [generalError]="f" [errorMessage]="errorMessage"
                       [formErrorMessages]="formErrorMessages"></p>
                </div>
            </div>
            <div class="form-footer" fxLayout="column" fxLayoutAlign="start stretch">
                <div class="button-row">
                    <button class="no-margin" type="submit" mat-raised-button color="primary" tabindex="3">Submit</button>
                </div>
            </div>
        </form>
    `,
})
export class PasswordUpdateComponent extends AutoFocusComponent implements OnInit {

    public form: FormGroup;

    @Input()
    public parent: ContentComponent;

    public errorMessage: string;

    public formErrorMessages: any = {
        'passwordConfirmed': 'Your password confirmation doesn\'t match.',
        'strengthPassword.length': 'At least 8 characters',
        'strengthPassword.lowercase': 'At least one lowercase character (a-z)',
        'strengthPassword.uppercase': 'At least one uppercase character (A-Z)',
        'strengthPassword.digit': 'At least one digit (0-9)',
        'strengthPassword.special': 'At least one special character',
    };

    private fb = new FormBuilder();

    constructor(@Inject('appConfig') public appConfig: any, private tracker: EventTrackingService,
                private translateService: TranslateService) {
        super();

        this.form = this.fb.group({
            newPassword: [''],
            confirmPassword: [''],
        }, {validator: new PasswordValidator('newPassword', 'confirmPassword').validate});
    }

    public ngOnInit(): void {
        this.translateService.get('keycloak.resetPassword').subscribe((translation) => this.parent.title = translation);
    }

    public submit(event: Event): void {
        this.tracker.trackEvent(new TrackingEvent('Password Reset', 'Profile'));

        (event.srcElement as any).submit();
    }
}
