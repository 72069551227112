import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';

@Component({
    selector: 'content-component',
    styleUrls: ['content.component.less'],
    template: `
        <div fxLayout="column" fxLayoutAlign="center center"
             class="content-component-wrapper">
            <div fxLayout="row" fxLayoutAlign="space-around" class="content-component">
                <div fxFlex="0 0 30.429em">
                    <mat-card [ngClass]="{'panel': true, 'with-link-button': withLinkButton}">
                        <mat-card-header>
                            <mat-card-title>
                                <div fxLayout="column" fxLayoutAlign="center">
                                    <div *ngIf="showLogo" fxLayout="row" fxLayoutAlign="space-around">
                                        <img [src]="appConfig.imagesPath + 'logo_kublr_cube.svg'">
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="space-around">
                                        <h1 [ngClass]="{'small-title': smallTitle}" [innerHTML]="title | safeHtml"></h1>
                                    </div>
                                </div>
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                              <ng-content></ng-content>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    `,
})
export class ContentComponent {

    @Input()
    public showLogo: boolean = true;

    @Input()
    public smallTitle: boolean = false;

    @Input()
    public title: string = '';

    @Input()
    public withLinkButton: boolean = false;

    constructor(@Inject('appConfig') public appConfig: any) {
    }
}
