import {Component, Inject, Input, OnInit} from '@angular/core';
import {AutoFocusComponent} from 'kublr-common/src';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PasswordValidator} from 'kublr-common/src';
import {EventTrackingService} from 'kublr-common/src';
import {TrackingEvent} from 'kublr-common/src';
import {ContentComponent} from '../layout/content.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'account-password-panel',
    styleUrls: ['account-common.less'],
    template: `
        <form *ngIf="!success" [action]="appConfig.passwordUrl" class="form account-from"
              (submit)="f.valid ? submit($event) : false" [ngClass]="{'ng-submitted': f.submitted}" [formGroup]="form"
              #f="ngForm" method="POST" novalidate fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="form-body" fxLayout="column" fxLayoutAlign="start stretch">
                <input type="hidden" id="stateChecker" name="stateChecker" [value]="appConfig.stateChecker">
                <div class="form-field" [controlError]="form.controls.password">
                    <div class="form-label" i18n="@@accountPasswordOld">Old Password</div>
                    <input class="form-input" type="password" name="password" formControlName="password"
                           autocomplete="off" required tabindex="1" *secretVisible="true; imagesPath: appConfig.imagesPath">
                </div>
                <div class="form-field" [controlError]="form.controls.newPassword">
                    <div class="form-label" i18n="@@accountPasswordNew">New Password</div>
                    <input class="form-input" type="password" name="password-new" [tooltip]="passwordStrengthTooltip"
                           formControlName="newPassword" minlength="8" strengthPassword autocomplete="off" required tabindex="2"
                           *secretVisible="true; imagesPath: appConfig.imagesPath">
                    <tooltip-content #passwordStrengthTooltip>
                        <password-strength-indicator [passwordControl]="form.controls.newPassword"></password-strength-indicator>
                    </tooltip-content>
                </div>
                <div class="form-field" [ngClass]="{invalid: form.hasError('passwordConfirmed')}"
                     [controlError]="form.controls.confirmPassword">
                    <div class="form-label" i18n="@@accountPasswordConfirm">Confirm Password</div>
                    <input class="form-input" [ngClass]="{'ng-invalid': form.hasError('passwordConfirmed')}"
                           type="password" name="password-confirm" formControlName="confirmPassword"
                           autocomplete="off" tabindex="3" *secretVisible="true; imagesPath: appConfig.imagesPath">
                </div>
                <div class="info-container collapsible" fxLayout="row" fxLayoutAlign="space-between center">
                    <p class="error-text" [generalError]="f" [errorMessage]="errorMessage"
                       [formErrorMessages]="formErrorMessages"></p>
                </div>
            </div>
            <div class="form-footer" fxLayout="column" fxLayoutAlign="start stretch">
                <div class="button-row">
                    <button class="no-margin" type="submit" mat-raised-button color="primary" tabindex="4" i18n="@@saveBtn">
                        Save
                    </button>
                </div>
                <div class="divider-container or" fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-divider></mat-divider>
                    <div class="or-label" i18n="@@orLabel">Or</div>
                    <mat-divider></mat-divider>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <a class="sign-action upper" [href]="appConfig.accountUrl" tabindex="5" i18n="@@accountPasswordEditAccountInfoBtn">
                        Edit Account Info
                    </a>
                    <a *ngIf="appConfig.baseUrl"  id="backToApplication" class="sign-action upper sign-action-top"
                       [href]="appConfig.baseUrl">
                        <img class="back-icon" [src]="appConfig.imagesPath + 'icons/icon_arrow_chevron_right.svg'"
                             alt="icon_arrow_chevron_right.svg"/>
                        <span class="back-icon" i18n="@@backToApplicationBtn">&#160;&#160;&#160;Back to Application</span>
                    </a>
                </div>
            </div>
        </form>
        <form *ngIf="success" class="form success-form" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="form-body info-page centered" fxLayout="column" fxLayoutAlign="start stretch">
                <p class="info-text">{{errorMessage}}</p>
            </div>
            <div *ngIf="appConfig.baseUrl" class="form-footer" fxLayout="column" fxLayoutAlign="start stretch">
                <div class="divider-container">
                    <mat-divider></mat-divider>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <a id="backToApplication" class="sign-action upper sign-action-top" [href]="appConfig.baseUrl">
                        <img class="back-icon" [src]="appConfig.imagesPath + 'icons/icon_arrow_chevron_right.svg'"
                             alt="icon_arrow_chevron_right.svg"/>
                        <span class="back-icon" i18n="@@backToApplicationBtn">&#160;&#160;&#160;Back to Application</span>
                    </a>
                </div>
            </div>
        </form>
    `,
})
export class AccountPasswordComponent extends AutoFocusComponent implements OnInit {

    public form: FormGroup;

    @Input()
    public parent: ContentComponent;
    public errorMessage: string;
    public success: boolean;

    public formErrorMessages: any = {
        'passwordConfirmed': 'Your password confirmation doesn\'t match.',
        'strengthPassword.length': 'At least 8 characters',
        'strengthPassword.lowercase': 'At least one lowercase character (a-z)',
        'strengthPassword.uppercase': 'At least one uppercase character (A-Z)',
        'strengthPassword.digit': 'At least one digit (0-9)',
        'strengthPassword.special': 'At least one special character',
    };

    private fb = new FormBuilder();

    constructor(@Inject('appConfig') public appConfig: any, private tracker: EventTrackingService,
                private translateService: TranslateService) {
        super();

        this.form = this.fb.group({
            password: [''],
            newPassword: [''],
            confirmPassword: [''],
        }, {validator: new PasswordValidator('newPassword', 'confirmPassword').validate});
    }

    public ngOnInit(): void {
        this.translateService.get('keycloak.changePassword').subscribe((translation) => this.parent.title = translation);
        this.parent.withLinkButton = true;

        if (this.appConfig.alert) {
            const self = this;
            setTimeout(() => {
                self.errorMessage = self.appConfig.alert.text;
                self.success = self.appConfig.alert.type === 'success';
            });
        }
    }

    public submit(event: Event): void {
        this.tracker.trackEvent(new TrackingEvent('Password Update', 'Profile'));

        (event.srcElement as any).submit();
    }
}
