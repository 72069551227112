import { OnInit, Directive } from '@angular/core';
import {MessageSeverity} from 'kublr-common/src';
import {NotificationService} from 'kublr-common/src';

@Directive()
export abstract class AlertComponent implements OnInit {

    constructor(public appConfig: any, protected notificationService: NotificationService) {
    }

    public ngOnInit(): void {
        if (this.appConfig.alert) {
            const self = this;

            const severity = (MessageSeverity as any)[self.appConfig.alert.type.toUpperCase()];
            setTimeout(() => {
                self.notificationService.notify(severity, severity === MessageSeverity.ERROR ? 'Error' : null, self.appConfig.alert.text);
            });
        }
    }
}
