import {Component, Inject, Input, OnInit} from '@angular/core';
import {ContentComponent} from './layout/content.component';

@Component({
    selector: 'error-page',
    styleUrls: ['error-page.component.less'],
    template: `
        <form class="form" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="form-body" fxLayout="column" fxLayoutAlign="start stretch">
                <div fxLayout="column" fxLayoutAlign="center center">
                    <h1 class="error-caption">Oops!</h1>
                    <img class="browser-error-img" [src]="appConfig.imagesPath + 'browser_error.svg'">
                    <p class="browser-error-text" [innerHtml]="appConfig.alert?.text | safeHtml"></p>
                </div>
            </div>
            <div class="form-footer" fxLayout="column" fxLayoutAlign="start stretch">
                <div class="divider-container">
                    <mat-divider></mat-divider>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <a *ngIf="appConfig.baseUrl"  id="backToApplication" class="sign-action upper sign-action-top"
                       [href]="appConfig.baseUrl">
                        <img class="back-icon" [src]="appConfig.imagesPath + 'icons/icon_arrow_chevron_right.svg'"
                             alt="icon_arrow_chevron_right.svg"/>
                        <span class="back-icon">&#160;&#160;&#160;Back to Application</span>
                    </a>
                </div>
            </div>
        </form>
    `,
})
export class ErrorPageComponent implements OnInit {

    @Input()
    private parent: ContentComponent;

    constructor(@Inject('appConfig') public appConfig: any) {
    }

    public ngOnInit(): void {
        this.parent.showLogo = false;
        this.parent.title = '';
        this.parent.withLinkButton = !!this.appConfig.baseUrl;
    }
}
