import {Component, Inject, Input, OnInit} from '@angular/core';
import {AutoFocusComponent, NotificationService} from 'kublr-common/src';
import {DomSanitizer} from '@angular/platform-browser';
import {ContentComponent} from '../layout/content.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'login-config-totp-panel',
    styleUrls: ['login-config-totp-panel.less'],
    template: `
        <div id="loginConfigTotpForm">
            <div #totpConfig>
                <form class="form" [action]="appConfig.totp.url" (submit)="f.valid ? $event.srcElement.submit() : false"
                      [ngClass]="{'ng-submitted': f.submitted}" #f="ngForm" method="POST" novalidate
                      fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="form-body" fxLayout="column" fxLayoutAlign="start stretch">
                        <ol class="instruction">
                            <li fxLayout="row" fxLayoutAlign="start start">
                                <p class="number">1</p>
                                <p i18n="@@loginConfigTotpInfo">
                                    Install Google Authenticator on your device. Application is available in
                                    <a href="https://play.google.com" target="_blank">Google Play</a> and Apple App Store.
                                </p>
                            </li>
                            <li *ngIf="appConfig.totp.mode !== 'manual'">
                                <div fxLayout="row" fxLayoutAlign="start start">
                                    <p class="number">2</p>
                                    <p i18n="@@loginConfigTotpScanBarcode">Open the application and scan the barcode:</p><br/>
                                </div>
                                <div fxLayout="column" fxLayoutAlign="center center">
                                    <div>
                                        <img [src]="sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64, '+
                                        appConfig.totp.secretQrCode)"
                                             alt="Figure: Barcode">
                                    </div>
                                    <p>
                                        <a [href]="appConfig.totp.manualUrl" id="mode-qr" i18n="@@loginConfigTotpUnableToScan">
                                            Unable to scan?
                                        </a>
                                    </p>
                                </div>
                            </li>
                            <ng-container *ngIf="appConfig.totp.mode === 'manual'">
                                <li>
                                    <div fxLayout="row" fxLayoutAlign="start start">
                                        <p class="number">2</p>
                                        <p i18n="@@loginConfigTotpEnterBarcode">Open the application and enter the key:</p><br/>
                                    </div>
                                    <div fxLayout="column" fxLayoutAlign="center center">
                                        <p><span id="kc-totp-secret-key" class="secret-encoded">{{appConfig.totp.secretEncoded}}</span></p>
                                        <p>
                                            <a [href]="appConfig.totp.qrUrl" id="mode-barcode" i18n="@@loginConfigTotpMoveToScan">
                                                Scan barcode?
                                            </a>
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div fxLayout="row" fxLayoutAlign="start start">
                                        <p class="number">3</p>
                                        <p i18n="@@loginConfigTotpSettings">
                                            Use the following configuration values if the application allows setting them:
                                        </p><br/>
                                    </div>
                                    <div fxLayout="column" fxLayoutAlign="start center">
                                        <p>
                                            <span id="kc-totp-type" i18n="@@loginConfigTotpSettingsType">
                                                Type: {{appConfig.totp.totpType === 'totp' ? 'Time-based' : 'Counter-based'}}<br/>
                                            </span>
                                            <span id="kc-totp-algorithm" i18n="@@loginConfigTotpSettingsAlgorithm">
                                                Algorithm: {{appConfig.totp.totpAlgorithm}}<br/>
                                            </span>
                                            <span id="kc-totp-digits" i18n="@@loginConfigTotpSettingsDigits">
                                                Digits: {{appConfig.totp.totpDigits}}<br/>
                                            </span>
                                            <ng-container *ngIf="appConfig.totp.totpType === 'totp'"
                                                          i18n="@@loginConfigTotpSettingsInterval">
                                                <span id="kc-totp-period">Interval: {{appConfig.totp.totpInterval}}<br/></span>
                                            </ng-container>
                                            <ng-container *ngIf="appConfig.totp.totpType === 'hotp'"
                                                          i18n="@@loginConfigTotpSettingsCounter">
                                                <span id="kc-totp-counter">Counter: {{appConfig.totp.totpCounter}}<br/></span>
                                            </ng-container>
                                        </p>
                                    </div>
                                </li>
                            </ng-container>
                            <li fxLayout="row" fxLayoutAlign="start start">
                                <p class="number">{{appConfig.totp.mode === 'manual' ? '4' : '3'}}</p>
                                <p i18n="@@loginConfigTotpEnterCode">
                                    Enter the one-time code provided by the application and click Save to finish the setup.
                                </p>
                            </li>
                        </ol>

                        <input type="hidden" id="totpSecret" name="totpSecret" [value]="appConfig.totp.secret"/>

                        <div class="form-field" [controlError]="f.controls.totp">
                            <div class="form-label" i18n="@@loginConfigTotpOneTimeCode">One-time code</div>
                            <input class="form-input" type="text" [(ngModel)]="totp" id="totp" name="totp"
                                   notBlank required autofocus autocomplete="off">
                        </div>
                        <div class="info-container collapsible" fxLayout="row" fxLayoutAlign="space-between center">
                            <p class="error-text" [generalError]="f" [errorMessage]="errorMessage"></p>
                        </div>
                    </div>
                    <div class="form-footer" fxLayout="column" fxLayoutAlign="start stretch">
                        <div class="button-row">
                            <button class="no-margin" type="submit" mat-raised-button color="primary" i18n="@@submitBtn">
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    `,
})
export class LoginConfigTotpComponent extends AutoFocusComponent implements OnInit {

    @Input()
    public parent: ContentComponent;
    public errorMessage: string;
    public totp: string;

    constructor(@Inject('appConfig') public appConfig: any, protected ns: NotificationService,
                public sanitizer: DomSanitizer, private translateService: TranslateService) {
        super();
    }

    public ngOnInit(): void {
        this.translateService.get(`keycloak.${this.appConfig.totp.enabled ? 'authenticator' : 'setupAuthenticator'}`)
            .subscribe((translation) => this.parent.title = translation);

        if (this.appConfig.alert) {
            const self = this;
            setTimeout(() => {
                self.errorMessage = self.appConfig.alert.text;
            });
        }
    }

    public onDeleteFormSubmit(event: any): boolean {
        event.srcElement.submit();
        window.location.reload();
        return true;
    }
}
