import {Component, Inject, Input, OnInit} from '@angular/core';
import {AutoFocusComponent} from 'kublr-common/src';
import {User} from 'kublr-common/src';
import {EventTrackingService} from 'kublr-common/src';
import {TrackingEvent} from 'kublr-common/src';
import {ContentComponent} from '../layout/content.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'account-info-panel[parent]',
    styleUrls: ['account-common.less'],
    template: `
        <form *ngIf="!success" [action]="appConfig.accountUrl" class="form account-from"
              (submit)="f.valid ? submit($event) : false" [ngClass]="{'ng-submitted': f.submitted}"
              #f="ngForm" method="POST" novalidate fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="form-body" fxLayout="column" fxLayoutAlign="start stretch">
                <input type="hidden" id="stateChecker" name="stateChecker" [ngModel]="appConfig.stateChecker">
                <div class="form-field" [controlError]="f.controls.username" [showText]="false">
                    <div class="form-label" i18n="@@accountInfoUsername">Username</div>
                    <input class="form-input"  type="text" [(ngModel)]="user.username" name="username"
                           #autoFocus maxlength="16" notBlank required tabindex="4">
                </div>
                <div class="form-field" [controlError]="f.controls.email" [showText]="false">
                    <div class="form-label" i18n="@@accountInfoEmail">Email</div>
                    <input class="form-input" type="email" [(ngModel)]="user.email" name="email"
                           maxlength="64" email notBlank required tabindex="3">
                </div>
                <div class="form-field">
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em">
                        <div class="form-field" [controlError]="f.controls.firstName" [showText]="false" fxFlex>
                            <div class="form-label" i18n="@@accountInfoFirstName">First Name</div>
                            <input class="form-input" type="text" [(ngModel)]="user.firstName" name="firstName"
                               maxlength="16" notBlank required tabindex="1">
                        </div>
                        <div class="form-field" [controlError]="f.controls.lastName" [showText]="false" fxFlex>
                            <div class="form-label" i18n="@@accountInfoLastName">Last Name</div>
                            <input class="form-input" type="text" [(ngModel)]="user.lastName" name="lastName"
                               maxlength="24" notBlank required tabindex="2">
                        </div>
                    </div>
                </div>
                <div class="info-container collapsible" fxLayout="row" fxLayoutAlign="space-between center">
                    <p class="error-text" [generalError]="f" [errorMessage]="errorMessage"></p>
                </div>
            </div>
            <div class="form-footer" fxLayout="column" fxLayoutAlign="start stretch">
                <div class="button-row">
                    <button type="submit" mat-raised-button color="primary" tabindex="5" i18n="@@saveBtn">Save</button>
                </div>
                <div class="divider-container or" fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-divider></mat-divider>
                    <div class="or-label" i18n="@@orLabel">Or</div>
                    <mat-divider></mat-divider>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <a class="sign-action upper" [href]="appConfig.passwordUrl" tabindex="6" i18n="@@accountInfoChangePasswordBtn">
                        Change Password
                    </a>
                    <a class="sign-action upper" [href]="appConfig.totpUrl" tabindex="7" i18n="@@accountInfoSetupAuthenticator">
                        Setup Authenticator
                    </a>
                    <a *ngIf="appConfig.baseUrl"  id="backToApplication" class="sign-action upper sign-action-top"
                       [href]="appConfig.baseUrl">
                        <img class="back-icon" [src]="appConfig.imagesPath + 'icons/icon_arrow_chevron_right.svg'"
                             alt="icon_arrow_chevron_right.svg"/>
                        <span class="back-icon" i18n="@@backToApplicationBtn">&#160;&#160;&#160;Back to Application</span>
                    </a>
                </div>
            </div>
        </form>
        <form *ngIf="success" class="form success-form" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="form-body info-page centered" fxLayout="column" fxLayoutAlign="start stretch">
                <p class="info-text">{{errorMessage}}</p>
            </div>
            <div *ngIf="appConfig.baseUrl" class="form-footer" fxLayout="column" fxLayoutAlign="start stretch">
                <div class="divider-container">
                    <mat-divider></mat-divider>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <a id="backToApplication" class="sign-action upper sign-action-top" [href]="appConfig.baseUrl">
                        <img class="back-icon" [src]="appConfig.imagesPath + 'icons/icon_arrow_chevron_right.svg'"
                             alt="icon_arrow_chevron_right.svg"/>
                        <span class="back-icon" i18n="@@backToApplicationBtn">&#160;&#160;&#160;Back to Application</span>
                    </a>
                </div>
            </div>
        </form>
    `,
})
export class AccountInfoComponent extends AutoFocusComponent implements OnInit {

    public user: User = new User();

    @Input()
    public parent: ContentComponent;

    public errorMessage: string;
    public success: boolean;
    private prevUser: User = new User();

    constructor(@Inject('appConfig') public appConfig: any, private tracker: EventTrackingService,
                private translateService: TranslateService) {
        super();

        if (this.appConfig.account) {
            this.user = Object.assign(this.user, this.appConfig.account);
        }
        this.prevUser = Object.assign(this.prevUser, this.user);
    }

    public ngOnInit(): void {
        this.translateService.get('keycloak.accountInfo').subscribe((translation) => this.parent.title = translation);
        this.parent.withLinkButton = true;

        if (this.appConfig.alert) {
            const self = this;
            setTimeout(() => {
                self.errorMessage = self.appConfig.alert.text;
                self.success = self.appConfig.alert.type === 'success';
            });
        }
    }

    public submit(event: Event): void {
        const fields: string[] = [];

        if (this.prevUser.firstName !== this.user.firstName) {
            fields.push('firstname');
        }
        if (this.prevUser.lastName !== this.user.lastName) {
            fields.push('lastname');
        }
        if (this.prevUser.email !== this.user.email) {
            fields.push('email');
        }
        if (this.prevUser.username !== this.user.username) {
            fields.push('username');
        }

        this.tracker.trackEvent(new TrackingEvent('info', 'profile:update', null,
            { username: this.prevUser.username, updated: fields.join() }));

        (event.srcElement as any).submit();
    }
}
