import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PasswordValidator} from 'kublr-common/src';
import {NotificationService} from 'kublr-common/src';
import {SignInComponent} from './sign-in.component';
import {SessionStorage} from 'ngx-webstorage';
import {User} from 'kublr-common/src';
import {EventTrackingService} from 'kublr-common/src';
import {TrackingEvent} from 'kublr-common/src';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'sign-up-panel[parent]',
    styleUrls: ['sign-up.component.less'],
    template: `
        <form [action]="appConfig.signUpUrl" class="form"
              (submit)="f.valid ? submit($event) : false" [ngClass]="{'ng-submitted': f.submitted}" [formGroup]="form"
              #f="ngForm" method="POST" novalidate fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="form-body" fxLayout="column" fxLayoutAlign="start stretch">
                <div class="form-field" [controlError]="form.controls.username" [showText]="false">
                    <div class="form-label" i18n="@@signUpUsername">Username</div>
                    <input class="form-input" type="text" [(ngModel)]="user.username" formControlName="username" name="username"
                           #autoFocus maxlength="16" notBlank required tabindex="1">
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em">
                    <div class="form-field" [controlError]="form.controls.firstName" [showText]="false">
                        <div class="form-label" i18n="@@signUpFirstName">First Name</div>
                        <input class="form-input" type="text" [(ngModel)]="user.firstName" formControlName="firstName" name="firstName"
                               maxlength="16" notBlank required tabindex="2">
                    </div>
                    <div class="form-field" [controlError]="form.controls.lastName" [showText]="false">
                        <div class="form-label" i18n="@@signUpLastName">Last Name</div>
                        <input class="form-input" type="text" [(ngModel)]="user.lastName" formControlName="lastName" name="lastName"
                               maxlength="24" notBlank required tabindex="3">
                    </div>
                </div>
                <div class="form-field" [controlError]="form.controls.email" [showText]="false">
                    <div class="form-label" i18n="@@signUpEmail">Email</div>
                    <input class="form-input" type="email" [(ngModel)]="user.email" formControlName="email" name="email"
                           maxlength="64" email notBlank required tabindex="4">
                </div>
                <div class="form-field" [controlError]="form.controls.password" [showText]="false">
                    <div class="form-label" i18n="@@signUpPassword">Password</div>
                    <input class="form-input" type="password" [(ngModel)]="user.password" name="password" formControlName="password"
                           [tooltip]="passwordStrengthTooltip"
                           minlength="8" strengthPassword required tabindex="5" *secretVisible="true; imagesPath: appConfig.imagesPath">
                    <tooltip-content #passwordStrengthTooltip>
                        <password-strength-indicator [passwordControl]="form.controls.password"></password-strength-indicator>
                    </tooltip-content>
                </div>
                <div class="form-field" [ngClass]="{invalid: form.hasError('passwordConfirmed')}"
                     [controlError]="form.controls.confirmPassword" [showText]="false">
                    <div class="form-label" i18n="@@signUpConfirmPassword">Confirm Password</div>
                    <input class="form-input" type="password" [(ngModel)]="user.confirmPassword" name="password-confirm"
                           formControlName="confirmPassword" required tabindex="6" *secretVisible="true; imagesPath: appConfig.imagesPath">
                    <span class="error" *ngIf="form.hasError('passwordConfirmed')" i18n="@@signUpPasswordConfirmedError">
                        Your password confirmation doesn’t match.
                    </span>
                </div>
                <div class="info-container collapsible" fxLayout="row" fxLayoutAlign="start center">
                    <p class="error-text" [generalError]="f" [errorMessage]="errorMessage"></p>
                </div>
            </div>

            <div class="form-footer" fxLayout="column" fxLayoutAlign="start stretch">
                <div class="button-row">
                    <button class="no-margin" type="submit" mat-raised-button color="primary" tabindex="7" i18n="@@signUpBtn">
                        Sign Up
                    </button>
                </div>
                <div class="divider-container or" fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-divider></mat-divider>
                    <div class="or-label" i18n="@@orLabel">Or</div>
                    <mat-divider></mat-divider>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <a class="sign-action upper" [href]="appConfig.logInUrl" tabindex="8" i18n="@@signInBtn">
                        Sign In
                    </a>
                </div>
            </div>
        </form>
    `,
})
export class SignUpComponent extends SignInComponent implements OnInit {

    @SessionStorage('new-user')
    public newUser: User;
    public form: FormGroup;

    private fb = new FormBuilder();

    constructor(@Inject('appConfig') public appConfig: any, protected ns: NotificationService, protected tracker: EventTrackingService,
                protected translateService: TranslateService) {
        super(appConfig, ns, tracker, translateService);

        this.form = this.fb.group({
            firstName: [''],
            lastName: [''],
            email: [''],
            username: [''],
            password: [''],
            confirmPassword: [''],
        }, {validator: new PasswordValidator('password', 'confirmPassword').validate});

        if (this.appConfig.alert) {
            this.user = this.newUser || this.user;

            this.newUser = null;
        }
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.translateService.get('keycloak.signUp').subscribe((translation) => this.parent.title = translation);
        this.parent.withLinkButton = true;
    }

    public submit(event: Event): void {
        this.tracker.trackEvent(new TrackingEvent('Sign-up', 'Profile'));

        this.newUser = this.user;
        (event.srcElement as any).submit();
    }
}
