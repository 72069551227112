import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {User} from 'kublr-common/src';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class UserRegService {
    private readonly userRegUrl: string;

    constructor(@Inject('appConfig') public appConfig: any, private http: HttpClient) {
        this.userRegUrl = this.appConfig.userRegBaseUrl;
    }

    public registerUser(user: User): Observable<any> {
        console.debug(`Registering user...`);

        const data: any = user;
        data.doNotSendEmail = true;

        return this.http.post<any>(`${this.userRegUrl}/user/register`, data);
    }
}
