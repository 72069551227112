import {Component, Inject, Input, OnInit} from '@angular/core';
import {AutoFocusComponent, NotificationService} from 'kublr-common/src';
import {DomSanitizer} from '@angular/platform-browser';
import {ContentComponent} from '../layout/content.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'account-totp-panel',
    styleUrls: ['account-common.less', 'account-totp-panel.less'],
    template: `
        <div id="toptForm">
            <div *ngIf="appConfig.totp.enabled; else totpConfig">
                <form class="form" fxLayout="column" fxLayoutAlign="space-between stretch"
                      [action]="appConfig.totp.removeUrl" (submit)="delf.valid ? onDeleteFormSubmit($event) : false"
                      #delf="ngForm" [ngClass]="{'ng-submitted': delf.submitted}" method="POST" novalidate>
                    <div class="form-body" fxLayout="column" fxLayoutAlign="start stretch">
                        <div class="form account-from authenticators" fxLayout="column" fxLayoutAlign="start stretch">
                            <div class="configured-auth-label" i18n="@@accountTotpAuths">Configured Authenticators:</div>
                            <div class="authenticators-list">
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <input type="hidden" id="stateChecker" name="stateChecker" [value]="appConfig.totp.stateChecker">
                                    <input type="hidden" id="submitAction" name="submitAction" value="Delete">
                                    <input type="hidden" id="credentialId" name="credentialId" [value]="appConfig.totp.credentialId">
                                    <span  class="authenticators-item" i18n="@@accountTotpAuthsMobile">
                                        &#8226;&#160;&#160;&#160;Mobile
                                    </span>
                                    <input type="image" alt="Submit" title="Delete" class="action authenticator-delete"
                                           [src]="appConfig.imagesPath + 'icons/delete.svg'"
                                           i18n-title="@@deleteIconTitle"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-footer" fxLayout="column" fxLayoutAlign="start stretch">
                        <div class="divider-container without-margin-top">
                            <mat-divider></mat-divider>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <a *ngIf="appConfig.baseUrl" class="sign-action upper sign-action-top" [href]="appConfig.baseUrl" tabindex="3">
                                <img class="back-icon" [src]="appConfig.imagesPath + 'icons/icon_arrow_chevron_right.svg'"
                                     alt="icon_arrow_chevron_right.svg"/>
                                <span class="back-icon" i18n="@@backBtn">&#160;&#160;&#160;Back</span>
                            </a>
                        </div>
                    </div>
                </form>
            </div>

            <ng-template #totpConfig>
                <form class="form account-from" [action]="appConfig.totp.url" (submit)="f.valid ? $event.srcElement.submit() : false"
                      [ngClass]="{'ng-submitted': f.submitted}" #f="ngForm" method="POST" novalidate
                      fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="form-body" fxLayout="column" fxLayoutAlign="start stretch">
                        <ol class="instruction">
                            <li fxLayout="row" fxLayoutAlign="start start">
                                <p class="number">1</p>
                                <p i18n="@@accountConfigTotpInfo">
                                    Install Google Authenticator on your device. Application is available in
                                    <a href="https://play.google.com" target="_blank">Google Play</a> and Apple App Store.
                                </p>
                            </li>
                            <li *ngIf="appConfig.totp.mode !== 'manual'">
                                <div fxLayout="row" fxLayoutAlign="start start">
                                    <p class="number">2</p>
                                    <p i18n="@@accountConfigTotpScanBarcode">Open the application and scan the barcode:</p><br/>
                                </div>
                                <div fxLayout="column" fxLayoutAlign="center center">
                                    <div>
                                        <img [src]="sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64, '+
                                        appConfig.totp.secretQrCode)"
                                             alt="Figure: Barcode">
                                    </div>
                                    <p>
                                        <a [href]="appConfig.totp.manualUrl" id="mode-qr" i18n="@@accountConfigTotpUnableToScan">
                                            Unable to scan?
                                        </a>
                                    </p>
                                </div>
                            </li>
                            <ng-container *ngIf="appConfig.totp.mode === 'manual'">
                                <li>
                                    <div fxLayout="row" fxLayoutAlign="start start">
                                        <p class="number">2</p>
                                        <p i18n="@@accountConfigTotpEnterBarcode">Open the application and enter the key:</p><br/>
                                    </div>
                                    <div fxLayout="column" fxLayoutAlign="center center">
                                        <p><span id="kc-totp-secret-key" class="secret-encoded">{{appConfig.totp.secretEncoded}}</span></p>
                                        <p>
                                            <a [href]="appConfig.totp.qrUrl" id="mode-barcode" i18n="@@accountConfigTotpMoveToScan">
                                                Scan barcode?
                                            </a>
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div fxLayout="row" fxLayoutAlign="start start">
                                        <p class="number">3</p>
                                        <p i18n="@@accountConfigTotpSettings">
                                            Use the following configuration values if the application allows setting them:
                                        </p><br/>
                                    </div>
                                    <div fxLayout="column" fxLayoutAlign="start center">
                                        <p>
                                            <span id="kc-totp-type" i18n="@@accountConfigTotpSettingsType">
                                                Type: {{appConfig.totp.totpType === 'totp' ? 'Time-based' : 'Counter-based'}}<br/>
                                            </span>
                                            <span id="kc-totp-algorithm" i18n="@@accountConfigTotpSettingsAlgorithm">
                                                Algorithm: {{appConfig.totp.totpAlgorithm}}<br/>
                                            </span>
                                            <span id="kc-totp-digits" i18n="@@accountConfigTotpSettingsDigits">
                                                Digits: {{appConfig.totp.totpDigits}}<br/>
                                            </span>
                                            <ng-container *ngIf="appConfig.totp.totpType === 'totp'">
                                                <span id="kc-totp-period" i18n="@@accountConfigTotpSettingsInterval">
                                                    Interval: {{appConfig.totp.totpInterval}}<br/>
                                                </span>
                                            </ng-container>
                                            <ng-container *ngIf="appConfig.totp.totpType === 'hotp'">
                                                <span id="kc-totp-counter" i18n="@@accountConfigTotpSettingsCounter">
                                                    Counter: {{appConfig.totp.totpCounter}}<br/>
                                                </span>
                                            </ng-container>
                                        </p>
                                    </div>
                                </li>
                            </ng-container>
                            <li fxLayout="row" fxLayoutAlign="start start">
                                <p class="number">{{appConfig.totp.mode === 'manual' ? '4' : '3'}}</p>
                                <p i18n="@@accountConfigTotpEnterCode">
                                    Enter the one-time code provided by the application and click Save to finish the setup.
                                </p>
                            </li>
                        </ol>

                        <input type="hidden" id="stateChecker" name="stateChecker" [value]="appConfig.totp.stateChecker"/>
                        <input type="hidden" id="totpSecret" name="totpSecret" [value]="appConfig.totp.secret"/>

                        <div class="form-field" [controlError]="f.controls.totp">
                            <div class="form-label" i18n="@@accountConfigTotpOneTimeCode">One-time code</div>
                            <input class="form-input" type="text" [(ngModel)]="totp" id="totp" name="totp"
                                   notBlank required autofocus autocomplete="off">
                        </div>
                        <div class="info-container collapsible" fxLayout="row" fxLayoutAlign="space-between center">
                            <p class="error-text" [generalError]="f" [errorMessage]="errorMessage"></p>
                        </div>
                    </div>
                    <div class="form-footer" fxLayout="column" fxLayoutAlign="start stretch">
                        <div class="button-row">
                            <button class="no-margin" type="submit" mat-raised-button color="primary" i18n="@@saveBtn">Save</button>
                        </div>
                        <div class="divider-container">
                            <mat-divider></mat-divider>
                        </div>
                        <div fxLayout="column" fxLayoutAlign="center center">
                            <a *ngIf="appConfig.baseUrl"  id="backToApplication" class="sign-action upper sign-action-top"
                               [href]="appConfig.baseUrl">
                                <img class="back-icon" [src]="appConfig.imagesPath + 'icons/icon_arrow_chevron_right.svg'"
                                     alt="icon_arrow_chevron_right.svg"/>
                                <span class="back-icon" i18n="@@backToApplicationBtn">&#160;&#160;&#160;Back to Application</span>
                            </a>
                        </div>
                    </div>
                </form>
            </ng-template>
        </div>
    `,
})
export class AccountTotpComponent extends AutoFocusComponent implements OnInit {

    @Input()
    public parent: ContentComponent;
    public errorMessage: string;
    public totp: string;

    constructor(@Inject('appConfig') public appConfig: any, protected ns: NotificationService,
                private sanitizer: DomSanitizer, private translateService: TranslateService) {
        super();
    }

    public ngOnInit(): void {
        this.translateService.get(`keycloak.${this.appConfig.totp.enabled ? 'authenticator' : 'setupAuthenticator'}`)
            .subscribe((translation) => this.parent.title = translation);

        if (this.appConfig.alert) {
            const self = this;
            setTimeout(() => {
                self.errorMessage = self.appConfig.alert.text;
            });
        }
    }

    public onDeleteFormSubmit(event: any): boolean {
        event.srcElement.submit();
        return true;
    }
}
