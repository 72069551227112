import {Component, Inject, Input, OnInit} from '@angular/core';
import {User} from 'kublr-common/src';
import {NotificationService} from 'kublr-common/src';
import {AutoFocusComponent} from 'kublr-common/src';
import {EventTrackingService} from 'kublr-common/src';
import {ContentComponent} from '../layout/content.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'sign-in-panel[parent]',
    styleUrls: ['sign-in.component.less'],
    template: `
        <form id="signInForm" [action]="appConfig.logInUrl" class="form login-form"
              (submit)="form.valid ? submit($event) : false" [ngClass]="{'ng-submitted': form.submitted}" #form="ngForm"
              method="POST" novalidate fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="form-body" fxLayout="column" fxLayoutAlign="start stretch">
                <div class="form-field" [controlError]="form.controls.username" [showText]="false">
                    <div class="form-label" i18n="@@signInUsername">Username or Email</div>
                    <input class="form-input" type="text" [(ngModel)]="user.username" name="username"
                           #autoFocus notBlank required tabindex="1">
                </div>
                <div class="form-field" [controlError]="form.controls.password" [showText]="false">
                    <div class="form-label" i18n="@@signInPassword">Password</div>
                    <input class="form-input" type="password" [(ngModel)]="user.password" name="password"
                           notBlank required tabindex="2"  *secretVisible="true; imagesPath: appConfig.imagesPath">
                </div>
                <div class="info-container" fxLayout="row" fxLayoutAlign="space-between start">
                    <p [fxFlex]="appConfig.resetPasswordAllowed ? 60 : 100"
                       class="error-text" [generalError]="form" [errorMessage]="errorMessage"></p>
                    <a *ngIf="appConfig.resetPasswordAllowed" class="forgot-password-link"
                       [href]="appConfig.forgotPasswordUrl" tabindex="3" i18n="@@signInForgotBtn">
                        Forgot password?
                    </a>
                </div>
            </div>

            <div class="form-footer" fxLayout="column" fxLayoutAlign="start stretch">
                <div class="button-row">
                    <button class="sign-in-button" name="login" type="submit" mat-raised-button color="primary" tabindex="4"
                            i18n="@@signInBtn">
                        Sign In
                    </button>
                </div>
                <div *ngIf="appConfig.registrationAllowed || !!appConfig.socialProviders && appConfig.socialProviders.length > 0"
                     class="divider-container or" fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-divider  fxFlex="45" fxFlex.xl="45" fxFlex.lg="45" fxFlex.md="40" fxFlex.sm="38" fxFlex.xs="38"></mat-divider>
                    <div class="or-label" i18n="@@orLabel">Or</div>
                    <mat-divider  fxFlex="45" fxFlex.xl="45" fxFlex.lg="45" fxFlex.md="40" fxFlex.sm="38" fxFlex.xs="38"></mat-divider>
                </div>
                <div *ngIf="appConfig.registrationAllowed" fxLayout="row" fxLayoutAlign="center center">
                    <a class="sign-action upper" [href]="appConfig.signUpUrl" tabindex="5" i18n="@@signUpBtn">
                        Sign Up
                    </a>
                </div>
                <ng-container *ngIf="appConfig.socialProviders">
                    <div *ngFor="let p of appConfig.socialProviders" fxLayout="row" fxLayoutAlign="center center">
                        <a id="social-{{p.alias}}" class="sign-action upper" [href]="p.loginUrl">
                            {{p.displayName}}
                        </a>
                    </div>
                </ng-container>
            </div>
        </form>
    `,
})
export class SignInComponent extends AutoFocusComponent implements OnInit {

    public user: User = new User();

    @Input()
    public parent: ContentComponent;

    public errorMessage: string;

    constructor(@Inject('appConfig') public appConfig: any, protected ns: NotificationService, protected tracker: EventTrackingService,
                protected translateService: TranslateService) {
        super();
    }

    public ngOnInit(): void {
        this.translateService.get('keycloak.signIn').subscribe((translation) => this.parent.title = translation);
        this.parent.withLinkButton = this.appConfig.registrationAllowed;

        if (this.appConfig.alert) {
            const self = this;
            setTimeout(() => {
                self.errorMessage = self.appConfig.alert.text;
            });
        }
    }

    public submit(event: Event): void {
        (event.srcElement as any).submit();
    }
}
