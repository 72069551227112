import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {KcpConfig, KcpConfigType} from 'kublr-common/src';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {SettingsProvider} from 'kublr-common/src';

@Component({
    selector: 'header-component',
    template: `
        <header class="black">
            <a [href]="appConfig.baseUrl || '/'" [ngClass]="{'no-events': !appConfig.baseUrl}">
                <div class="logo-container">
                    <img class="logo" [src]="appConfig.imagesPath + 'logo.svg'"
                         alt="logo_kublr_black.svg"/>
                </div>
            </a>
            <nav>
                <ul class="nav">
                    <li class="notice" fxLayout="row wrap" fxLayoutAlign="center center"
                        fxLayoutGap="0.7em">
                    </li>
                    <li *ngIf="!appConfig.vendorFree" id="blog-url">
                        <a [href]="appConfig.blogUrl" target="_blank" i18n="@@keycloakThemeHeaderBlog">Blog</a>
                    </li>
                    <li *ngIf="!appConfig.vendorFree" id="docs-url">
                        <a [href]="appConfig.docsUrl" target="_blank" i18n="@@keycloakThemeHeaderDocumentation">Documentation</a>
                    </li>
                    <ng-container *ngIf="showLanguagesSwitch">
                        <li [ngClass]="{expanded: localeMenuOpened}" class="locale-menu expandable icon"
                            (onMenuOpen)="localeMenuOpened=true" (onMenuClose)="localeMenuOpened=false" [matMenuTriggerFor]="localeMenu"
                            id="selected-locale-trigger">
                            <a id="selected-locale">{{appConfig.locale.current}}</a>
                            <mat-menu class="locale-menu-dropdown" #localeMenu="matMenu" x-position="before"
                                      [overlapTrigger]="false" id="locale-menu">
                                <a *ngFor="let l of availableLocales" [href]="l.url" mat-menu-item>
                                    {{l.label}}
                                </a>
                            </mat-menu>
                        </li>
                    </ng-container>
                </ul>
            </nav>
        </header>
    `,
    styleUrls: ['../../../common-styles/src/less/component/header-common.less', 'header.component.less'],
})
export class HeaderComponent implements OnInit, OnDestroy {

    public localesIsAvailable: boolean;
    public localeMenuOpened: boolean;
    public availableLanguages: string[];
    public availableLocales: any[];
    public showLanguagesSwitch: boolean;

    protected subscriptions: Subscription = new Subscription();

    constructor(@Inject('appConfig') public appConfig: any, private settingsProvider: SettingsProvider) {
        this.localesIsAvailable = !!appConfig.locale && !!appConfig.locale.current && !!appConfig.locale.supported &&
            appConfig.locale.supported.length > 0;
    }

    public ngOnInit() {
        if (this.localesIsAvailable) {
            const settingsRequests: Array<Observable<any>> = [];
            settingsRequests.push(
                this.settingsProvider.getSettingByName(this.appConfig.apiBaseUrl, KcpConfigType.INTERNATIONALISATION_ENABLED),
            );
            settingsRequests.push(
                this.settingsProvider.getSettingByName(this.appConfig.apiBaseUrl, KcpConfigType.AVAILABLE_LANGUAGES),
            );

            this.subscriptions.add(
                forkJoin(settingsRequests).subscribe((settings: Array<KcpConfig<any>>) => {
                    if (settings) {
                        const internationalisationEnabled: KcpConfig<boolean> = settings[0];
                        const availableLanguagesSetting: KcpConfig<string[]> = settings[1];
                        this.availableLanguages = !!availableLanguagesSetting && availableLanguagesSetting.data ?
                            availableLanguagesSetting.data : [];
                        const localeCodeParam: string = 'kc_locale=';
                        this.availableLocales = [].concat(this.appConfig.locale.supported).filter((l: any) => {
                            const index = l.url.indexOf(localeCodeParam);
                            const code = l.url.substring(index + localeCodeParam.length, index + localeCodeParam.length + 2);
                            return this.availableLanguages.includes(code);
                        });
                        this.showLanguagesSwitch = !!internationalisationEnabled && internationalisationEnabled.data &&
                            this.availableLanguages.length > 0 || this.appConfig.locale.current !== 'English';
                    }
                }, (error) => {
                    console.error(error);
                    this.availableLocales = [].concat(this.appConfig.locale.supported);
                    this.showLanguagesSwitch = this.localesIsAvailable;
                }),
            );
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
