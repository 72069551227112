import {Component, Inject, Input, OnInit} from '@angular/core';
import {ContentComponent} from '../layout/content.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'reset-password-success-panel[parent]',
    styleUrls: ['reset-password-success.component.less'],
    template: `
        <form class="form" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="form-body info-page centered" fxLayout="column" fxLayoutAlign="start stretch">
              <p class="info-text">We sent a link to reset your password in your email inbox.</p>
            </div>
            <div class="form-footer" fxLayout="column" fxLayoutAlign="start stretch">
                <a *ngIf="appConfig.baseUrl" class="full-width no-margin" mat-raised-button color="primary"
                   [href]="appConfig.baseUrl">
                    Sign In
                </a>
            </div>
        </form>
    `,
})
export class ResetPasswordSuccessComponent implements OnInit {

    @Input()
    private parent: ContentComponent;

    constructor(@Inject('appConfig') public appConfig: any, private translateService: TranslateService) {
    }

    public ngOnInit(): void {
        this.translateService.get('keycloak.restorePassword').subscribe((translation) => this.parent.title = translation);
    }
}
