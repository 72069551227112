import {Component, Inject, Input, OnInit} from '@angular/core';
import {ContentComponent} from '../layout/content.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'email-verification',
    styleUrls: ['email-verification.component.less'],
    template: `
        <div class="info-page centered">
            <p class="line-height-double" i18n="@@emailVerificationInfo">
                An email with instructions to verify your email address has been sent to you.<br/>
                If you haven't received a verification code, please <a [href]="appConfig.loginEmailVerificationUrl">click here</a> to
                re-send the email.
            </p>
        </div>
    `,
})
export class EmailVerificationComponent implements OnInit {

    @Input()
    public parent: ContentComponent;

    constructor(@Inject('appConfig') public appConfig: any, private translateService: TranslateService) {
    }

    public ngOnInit(): void {
        this.translateService.get('keycloak.verification').subscribe((translation) => this.parent.title = translation);
    }
}
